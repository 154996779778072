export default {
    name: "Chart",
    created() {
        this.$nextTick(function() {
            this.getChartData()
        });
    },
    mounted() {

    },
    props: [
        'chartRic',
        'chartIndex',
        'underlyingCurr'
    ],
    data() {
        return {
            chartDate: 'm'
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取chart data
        getChartData() {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$$.mibb + "GetChartData?token=webkey&type=0&period=" + this.chartDate + "&ric=" + this.chartRic,
                success: (res) => {
                    if (res.status == 'SUCCESS') {
                        var ulenth = res.underlyingDataList.length;
                        var wlenth = res.warrantDataList.length;
                        var arr = [];
                        if (ulenth == wlenth) { //长度一样 
                            for (var i = 0; i < res.warrantDataList.length; i++) {
                                var obj = {}
                                obj['time'] = this.chartDate == "d" ? res.warrantDataList[i].time : res.warrantDataList[i].date;
                                obj['ubid'] = this.chartDate == "d" ? res.underlyingDataList[i].bid : res.underlyingDataList[i].close;
                                obj['wbid'] = res.warrantDataList[i].bid;
                                arr.push(obj);
                            }
                        } else if (ulenth > wlenth) { //underlyingdatalist
                            for (var i = 0; i < res.underlyingDataList.length; i++) {
                                var obj = {};
                                obj['time'] = this.chartDate == "d" ? res.underlyingDataList[i].time : res.underlyingDataList[i].date;
                                obj['ubid'] = this.chartDate == "d" ? res.underlyingDataList[i].bid : res.underlyingDataList[i].close;
                                $.each(res.warrantDataList, function(index, data) {
                                    if (this.chartDate == "d") {
                                        if (res.underlyingDataList[i].time == data.time) {
                                            obj['wbid'] = data.bid;
                                        }
                                    } else {
                                        if (res.underlyingDataList[i].date == data.date) {
                                            obj['wbid'] = data.bid;
                                        }
                                    }
                                });
                                arr.push(obj);
                            }
                        } else if (wlenth > ulenth) { //warrantdatalist
                            for (var i = 0; i < res.warrantDataList.length; i++) {
                                var obj = {};
                                obj['time'] = this.chartDate == "d" ? res.warrantDataList[i].time : res.warrantDataList[i].date;
                                $.each(res.underlyingDataList, function(index, data) {
                                    if (this.chartDate == "d") {
                                        if (data.time == res.warrantDataList[i].time) {
                                            obj['ubid'] = data.bid;
                                        }
                                    } else {
                                        if (data.date == res.warrantDataList[i].date) {
                                            obj['ubid'] = data.close;
                                        }
                                    }
                                });
                                obj['wbid'] = res.warrantDataList[i].bid;
                                arr.push(obj);
                            }
                        }
                        this.detailsChartLoad(arr);
                    }
                },
                error: function(XMLHttpRequest) {}
            });
        },
        // chart图显示
        detailsChartLoad(chartData) {
            $("#goChart" + this.chartIndex).empty(); //先清空
            var wbidArr = [];
            var ubidArr = [];
            $.each(chartData, function(index, data) {
                wbidArr.push(data.wbid);
                ubidArr.push(data.ubid);
            });
            var wbidy = this.getMaxMinNum(wbidArr);
            var ubidy = this.getMaxMinNum(ubidArr);
            var then = this;
            anychart.onDocumentReady(function() {
                //assigndata
                var arr = [];
                $.each(chartData, function(index, datas) {
                        var obj = {};
                        obj['date'] = datas.time;
                        obj['bid'] = then.$$.formatNumber(datas.wbid, 3, 0);
                        obj['price'] = then.$$.formatNumber(datas.ubid, 3, 0);
                        arr.push(obj);
                    })
                    //赋值
                var dataSet = anychart.data.set(arr);
                var seriesData_1 = dataSet.mapAs({ 'x': "date", 'value': "bid" });
                var seriesData_2 = dataSet.mapAs({ 'x': "date", 'value': "price" });

                //生成chart
                var chart = anychart.area();
                chart.padding(0, 0, 0, 0)
                chart.animation(true);

                // //十字线
                var crosshair = chart.crosshair();
                crosshair.enabled(true)
                    .yStroke('#ffffff')
                    .xStroke('#ffffff')
                    .zIndex(39);
                crosshair.xStroke('#000000');
                crosshair.yStroke('#000000');

                // //设置x轴
                var axis = chart.xAxis();
                var labels = axis.labels();
                if (then.chartDate == "d") {
                    labels.format(function() { // Set format for date time
                        return anychart.format.dateTime(then.$$.changeToLocalTime(this.value), 'HH:mm');
                    });
                } else {
                    labels.format(function() { // Set format for date time
                        return anychart.format.dateTime(then.$$.changeToLocalTime(this.value), 'dd/MM');
                    });
                }

                // //悬浮
                var setupSeriesLabels = function(series, name) {
                    series.name(name)
                        .fill(function() {
                            return this.sourceColor + ' 0.8'
                        });
                    series.hovered().stroke('3 #ffffff 1');
                    series.hovered().markers()
                        .enabled(true)
                        // .type('circle')
                        .size(4)
                        .stroke('1.5 #ffffff');
                    series.markers().zIndex(100);
                };

                // // set titles for axises
                chart.xAxis().title(false);
                chart.yAxis().title(false);
                chart.interactivity().hoverMode('by-x');
                chart.tooltip().displayMode('union');
                chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0]);

                // // force chart to stack values by Y scale.
                chart.yScale().stackMode('value');

                // // set grid settings
                chart.xGrid().enabled(true);

                // //set font size / font-color
                chart.yAxis().labels().enabled(true).fontColor('#181515').fontSize(11);
                chart.yAxis(1).labels().enabled(true).fontColor('#181515').fontSize(11);
                chart.xAxis().labels().enabled(true).fontColor('#181515').fontSize(11);

                // //y -价钱3位小数
                chart.yAxis().labels().format(function() {
                    return then.$$.formatNumber(this.value, 3, 0);
                });
                chart.yAxis(1).labels().format(function() {
                    return then.$$.formatNumber(this.value, 3, 0);
                });

                // // Add area chart1
                var lineSeries = chart.area(seriesData_2);
                lineSeries.name(then.$t('WTs.Up') + ' (' + then.underlyingCurr + ')').yScale();
                lineSeries.yScale().maximum(ubidy[0]);
                lineSeries.yScale().minimum(ubidy[1]);
                lineSeries.tooltip().enabled(true);
                // lineSeries.fill('rgba(255,198,0, 0.7)');
                lineSeries.fill('rgba(174,174,174, 0.7)');
                lineSeries.normal().stroke("#7d7d7d");
                lineSeries.tooltip().format(function(e) {
                    var seriesName = this.seriesName;
                    var value = this.value;
                    return seriesName + ": " + then.$$.formatNumber(this.value, 3, 0);
                });

                // //add area chart2
                var scale = anychart.scales.linear();
                chart.yAxis(1).orientation('right').scale(scale);
                var series = chart.area(seriesData_1);
                setupSeriesLabels(series, then.$t('WCr.WB') + ' (' + then.underlyingCurr + ')');
                series.yScale(scale);
                // series.fill('rgba(91,194,231, 0.7)');
                series.fill('rgba(255,217,102, 0.7)');
                series.normal().stroke("#FFC600");
                series.yScale().maximum(wbidy[0]);
                series.yScale().minimum(wbidy[1]);

                console.log(wbidArr);

                if (Math.max.apply(Math, wbidArr) == Math.min.apply(Math, wbidArr)) {
                    series.yScale().maximum(wbidy[0] * 1.5);
                    series.yScale().minimum(wbidy[1] / 2);
                }

                series.tooltip().enabled(true);
                series.tooltip().format(function(e) {
                    var seriesName = this.seriesName;
                    var value = this.value;
                    return seriesName + ": " + then.$$.formatNumber(this.value, 3, 0);
                });

                // // Get xGrid.
                var xGrid = chart.xGrid();
                xGrid.stroke('1 #f2f2f2');

                // set container id for the chart
                chart.container('goChart' + then.chartIndex);

                chart.draw();
                $(".anychart-credits").remove();
                $(".ChartButtonBox>div>button").removeAttr("disabled");
            });
        },
        getMaxMinNum(arr) {
            var isEqeal = this.isAllEqual(arr);
            var mmArr = [];
            if (isEqeal) { //如果都相同
                mmArr.push(arr[0] * 2);
                mmArr.push(arr[0] % 2);
                return mmArr;
            } else {
                var max = Math.max.apply(Math, arr);
                mmArr.push(max);
                mmArr.push(Math.min.apply(Math, arr));
                return mmArr;
            }
        },
        isAllEqual(array) {
            if (array.length > 0) {
                return !array.some(function(value, index) {
                    return value !== array[0];
                });
            } else {
                return true;
            }
        },
        // 转换时间数据
        switchTimeData: function(id, time) {
            $('#WatchChart' + this.chartIndex).find('.ChartButtonBox').find('div').eq(id).find('.btn').addClass('WatchChartmark')
            $('#WatchChart' + this.chartIndex).find('.ChartButtonBox').find('div').eq(id).siblings('div').find('.btn').removeClass('WatchChartmark')
            this.chartDate = time
            this.getChartData()
        },
    }
};