var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content_layout",attrs:{"id":"DailyHighlights"}},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',{staticClass:"content_hint"},[_vm._v("\n    Underlying and it's corresponding Maybank issued warrants.\n  ")]),_vm._v(" "),_c('div',{staticClass:"message"},_vm._l((_vm.DailyHighlightsData),function(di,diindex){return _c('div',{key:diindex},[_c('p',{staticClass:"clickable",on:{"click":function($event){return _vm.$$.toRelevantPage(true, di.overallData.dsply_nmll)}}},[_vm._v("\n        "+_vm._s(di.overallData.dsply_nmll)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"row contentBox"},[_c('ul',{staticClass:"col-lg-6"},_vm._l((di.nameData),function(ni,nindex){return _c('li',{key:nindex,staticClass:"row"},[_c('span',{staticClass:"col-sm-6",class:ni.top ? 'HintCursor' : '',on:{"mouseenter":function($event){_vm.$$.theCorrespondingDataHint(
                  true,
                  ni.name.split(' ')[0],
                  $event,
                  ni.top,
                  ni.left
                )},"mouseleave":function($event){_vm.$$.theCorrespondingDataHint(
                  false,
                  ni.name.split(' ')[0],
                  $event,
                  ni.top,
                  ni.left
                )}}},[_vm._v("\n              "+_vm._s(ni.name)+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"col-sm-6"},[_vm._v("\n              "+_vm._s(ni.data)+"\n              "),(ni.data2)?_c('i',{class:ni.data2.split('%')[0] >= 0 ? 'green' : 'red'},[_vm._v("\n                "+_vm._s(ni.data2)+"\n              ")]):_vm._e()])])}),0),_vm._v(" "),_c('Chart',{staticClass:"col-lg-6",attrs:{"chartRic":((di.overallData.underlying_ric) + "," + (di.overallData.ric)),"chartIndex":diindex,"underlyingCurr":_vm.$$.curreryRetrun(di.overallData.underlying_curr)}})],1)])}),0),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"40px","text-align":"center","font-size":"13px"}},[_vm._v("\n    All market data is delayed 15 mins unless otherwise indicated by\n    timestamp.\n  ")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nameHintBox"},[_c('span',{staticClass:"DeltaHint"},[_c('strong',[_vm._v("Delta:")]),_vm._v(" Measures the theoretical movement in warrant\n      price with the corresponding one tick move in underlying price.\n    ")]),_vm._v(" "),_c('span',{staticClass:"ImpliedVolatilityHint"},[_c('strong',[_vm._v("Implied Volatility:")]),_vm._v(" Measures the expected volatility of\n      a underlying share over the life of the warrant. With all factors being\n      equal, warrants that have high levels of implied volatility will result\n      in high-priced premiums.\n    ")]),_vm._v(" "),_c('span',{staticClass:"PremiumHint"},[_c('strong',[_vm._v("Premium:")]),_vm._v(" Measures the percentage increase (for call\n      warrants) or percentage decrease (for put warrants) of price that the\n      underlying share needs to reach at expiry in order to achieve break\n      even.\n    ")]),_vm._v(" "),_c('span',{staticClass:"SensitivityHint"},[_c('strong',[_vm._v("Sensitivity:")]),_vm._v(" The number of ticks the underlying price\n      needs to move by for a corresponding one tick movement in the warrant.\n    ")]),_vm._v(" "),_c('span',{staticClass:"EffectiveGearingHint"},[_c('strong',[_vm._v("Effective Gearing:")]),_vm._v(" Measures percentage change of the\n      warrant price with a 1% change of its underlying share price.\n    ")]),_vm._v(" "),_c('span',{staticClass:"ThetaHint"},[_c('strong',[_vm._v("Theta:")]),_vm._v(" The number of days it takes for the warrant time\n      value to decrease by one tick size.\n    ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"content_title"},[_c('span',[_vm._v("Daily Highlights")])])}]

export { render, staticRenderFns }