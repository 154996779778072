import { render, staticRenderFns } from "./DailyHighlights.vue?vue&type=template&id=6fa53b98&scoped=true&"
import script from "./DailyHighlights.js?vue&type=script&lang=js&"
export * from "./DailyHighlights.js?vue&type=script&lang=js&"
import style0 from "./DailyHighlights.vue?vue&type=style&index=0&id=6fa53b98&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa53b98",
  null
  
)

export default component.exports